import { useEffect, useState } from 'react';
import Select from 'react-select';
import { PulseLoader } from 'react-spinners';
import './App.css';
import mustardLogo from './img/mustard.gif';
import useWindowDimensions from './useWindowDimensinos';

const dumpTools = [
  {
    name: 'Tool 5',
    blockchain: 'ethereum',
    description: 'a description',
    url: 'bla bla bla',
    category: 'Category 2'
  },
  {
    name: 'Tool 1',
    blockchain: 'ethereum',
    description: 'a description',
    url: 'bla bla bla',
    category: 'Category 1'
  },
  {
    name: 'Tool 5',
    blockchain: 'solana',
    description: 'a description',
    category: 'Category 1'
  },
];

const dumpCategories = [
  {
    value: 'Category 1',
    label: 'NFT Marketplaces'
  },
  {
    value: 'Category 2',
    label: 'NFT Trading'
  }
];

const blockchains = [
  { 
    value: 'all',
    label: 'All chains',
  },
  {
    value: 'Eth',
    label: 'Ethereum',
  },
  {
    value: 'Solana',
    label: 'Solana',
  },
  {
    value: 'ADA',
    label: 'ADA',
  },
]

const colors = ["#da4c58", "#68d5ea", "#5441e8", "#e5c146", "#cb488c", "#73f883", "#2a4ed8", "#ebf268"]

const splitArrayToFour = (arr, cols) => {
  const tmp = cols === 1 ? [[]]: cols === 2 ? [[],[]] : cols === 3 ? [[],[],[]] :[[],[],[],[]];
  console.log(tmp);
  for(let i = 0; i < arr.length; i += 1) {
    tmp[i%cols].push(arr[i]);
  }

  return tmp;
}

const getColumnsFromWidth = (width) => {
  if (width < 695) return 1;
  if (width < 1035) return 2;
  if (width < 1375) return 3;
  return 4;
}

function App() {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [staticCategories, setStaticCategories] = useState([]);
  const [tools, setTools] = useState([]);
  const [displayedChain, setDisplayedChain] = useState(blockchains[0]);
  const { width, height } = useWindowDimensions();
  const [columns, setColumns] = useState(getColumnsFromWidth(width));

  useEffect(() => {
    fetch('https://whitelists.regulars.info/fetch-nft')
    .then((res) => res.json())
    .then((data) => {
      setLoading(false);
      setCategories(splitArrayToFour(data.categories, columns));
      setStaticCategories(data.categories);
      setTools(data.results);
    })
    .catch((e) => { 
      setLoading(false);
      console.log(e);
    });
  },[]);

  useEffect(() => {
    setColumns(getColumnsFromWidth(width));
  }, [width]);

  useEffect(() => {
    setCategories(splitArrayToFour(staticCategories, columns));
  }, [columns]);

  const onChange = (option) => {
    setDisplayedChain(blockchains[blockchains.findIndex((el) => el.value === option.value)]);
  }

  const updateClicks = (sheetTitle, sheetRow) => {
    fetch('https://whitelists.regulars.info/used-link?sheetTitle='+sheetTitle+'&sheetRow='+sheetRow);
  }

  return (
    <div className="App">
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}>
        <PulseLoader color="white" loading={loading} size={50}/>
      </div>
      <header className="App-header">
        <img src={mustardLogo} style={{ width: "100%", maxWidth: "300px"}} alt="Mustard Tools!"/>
      </header>
      <div style={{ width: '100%', maxWidth: '400px', margin: '20px auto'}}>
        <Select
          options={blockchains}
          value={displayedChain}
          onChange={onChange}
        />
      </div>
      <a href="https://docs.google.com/forms/d/e/1FAIpQLSefdkIZbzjAgOVucAiUUokQH3qO_ho_GF9tZuGvsg4NLYrOCQ/viewform"
        style={{
          color: 'white',
          position: 'absolute',
          top: '20px',
          right: '20px',
          textDecoration: 'none'
        }}
        target="_blank"
        rel="noreferrer"
        >Submit a tool</a>
      <div style={{
        display: 'flex',
        gap: '40px',
        flexWrap: 'wrap',
        width: "100%",
        maxWidth: "1400px",
        margin: "0 auto",
        justifyContent: "center",
      }}>
        {categories.map((subCategories, ind) => (
          <div style={{ width: "100%", maxWidth: "300px"}}>
            {subCategories.filter((cat) => tools
          .filter((elem) => (elem.blockchain === displayedChain.value || displayedChain.value === 'all'))
          .filter((elem) => cat.value === elem.category).length > 0).map((cat, index) => {
          if (tools.find((elem) => elem.category === cat.value)) {
            return (
              <>
                <h3 style={{ marginTop: "40px",paddingBottom: "20px", borderBottom: "2px solid "+ colors[ind+(index*columns)] }}>
                  {cat.label}  
                </h3> 
                <ul>
                  {tools.filter((elem) => elem.category === cat.value)
                  .filter((elem) => (elem.blockchain === displayedChain.value || displayedChain.value === 'all'))
                  .map((elem) => (
                    <li style={{marginBottom: "20px"}}>
                      {elem.url ? (
                        <>
                        <a onClick={() => updateClicks(cat.value, elem.sheetRow)} href={elem.url} title={elem.name} target="_blank" rel="noreferrer"
                        style={{textDecoration: "none", display: "block", fontSize: "20px"}}>
                          {elem.name}
                        </a>
                        <span style={{color: colors[ind+(index*columns)], fontSize: "14px"}}>{elem.description}</span>
                        </>
                      ) : (
                        <>
                        <span title={elem.name}>{elem.name}</span>
                        <span style={{color: colors[ind+(index*columns)], fontSize: "14px"}}>{elem.description}</span>
                        </>
                        )
                        }
                    </li>
                  ))}
                </ul>
              </>
            )
          }
          return null;
        })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default App;
